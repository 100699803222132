<template>
  <div class="mx-auto mb-20 mt-10 flex max-w-screen-xl flex-col rounded-lg px-5 md:mt-20 md:flex-row md:px-12">
    <div class="order-2 flex flex-col justify-center text-gray-900 md:order-1 md:w-3/6 md:pr-4">
      <h1 class="text-3xl leading-tight md:text-5xl">Ups! Diese Seite ist leider nicht verfügbar.</h1>
      <p class="mt-2 leading-6 md:mt-4">
        Die Terminbuchung und alle Infos zu unseren Ärzt:innen und Leistungen findest du hier:
      </p>
      <div class="mt-2 md:mt-4">
        <AppButton @click="handleClearError">Zu den Fachrichtungen</AppButton>
      </div>
    </div>
    <ThinkingPersonIllustration class="order-1 mx-auto mb-4 mt-6 h-auto w-44 md:ml-auto md:mr-0 md:mt-0 md:w-1/3" />
  </div>
</template>

<script setup lang="ts">
import ThinkingPersonIllustration from "assets/svg/thinkingPersonIllustration.svg?skipsvgo";
function handleClearError(): void {
  clearError({ redirect: "/fachrichtungen" });
}
</script>

<style scoped></style>
