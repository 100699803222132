<template>
  <div>
    <NuxtLayout name="default">
      <Error404 v-if="error.statusCode === 404" />
      <section v-else class="error-wrapper flex h-screen w-screen items-center justify-center bg-brick-100">
        <div class="max-w-md p-5">
          <div class="mx-auto max-w-screen-sm text-center">
            <h1 class="text-7xl text-gray-900 lg:text-9xl">
              {{ error.statusCode }}
            </h1>
            <p class="mb-4 text-xl font-bold leading-tight text-gray-900 md:text-4xl">
              Ups! Da ist leider etwas schiefgelaufen.
            </p>
            <p class="m-4 text-lg font-light text-gray-700 md:m-8">
              {{ error.message }}
            </p>
            <AppButton class="mx-auto" @click="handleClearError">
              Zurück zu haelsi
              <SvgoArrowRight class="text-lg" />
            </AppButton>
          </div>
        </div>
      </section>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from "nuxt/app";
import { useGlobalStore } from "~/store/global";

defineProps<{
  error: NuxtError;
}>();

const { fetchGlobal } = useGlobalStore();
fetchGlobal();

function handleClearError(): void {
  clearError({ redirect: "/" });
}
</script>

<style scoped>
.error-wrapper {
  font-family: "lelo", arial, sans-serif;
}
</style>
