import type { RouteLocationNormalized } from "vue-router";

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized, from: RouteLocationNormalized): void => {
  if (import.meta.server) return;

  // on the first visit, to and from are apparently the same
  // (don't quote me on that though)
  const isSamePath = to.path === from.path;

  if (!isSamePath) {
    to.meta.freshInstance = false;

    return;
  }

  to.meta.freshInstance = JSON.stringify(to.query) === JSON.stringify(from.query);
});
