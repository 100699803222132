import type { RouterConfig } from "@nuxt/schema";

export default <RouterConfig>{
  routes: (_routes) => [
    ..._routes,
    {
      path: "/fachrichtung",
      redirect: () => {
        return { path: "/fachrichtungen" };
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return {
        behavior: "smooth",
        el: to.hash,
      };
    }

    return { left: 0, top: 0 };
  },
};
