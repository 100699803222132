export function isNotNull<T>(input: T | null): input is T {
  return input !== null;
}

export function isNull(input: any): input is null {
  return input === null;
}

export function isNotUndefined<T>(input?: T): input is T {
  return input !== undefined;
}

export function isUndefined(input: any): input is undefined {
  return input === undefined;
}

export function isNotNullOrUndefined<T>(input: T): input is NonNullable<T> {
  return isNotNull<T>(input) && isNotUndefined<T>(input);
}

export function isNullOrUndefined(input: any): input is null | undefined {
  return isNull(input) || isUndefined(input);
}

function isString(input: any): input is string {
  return typeof input === "string";
}

function isNotEmptyString(value: string): boolean {
  const normalizedString = value.trim();

  return normalizedString !== "";
}

export function isStringAndNotEmpty(value: any): boolean {
  return isString(value) && isNotEmptyString(value);
}
