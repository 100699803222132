// shamelessly stolen from https://stackoverflow.com/a/74620925/838733
export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server && to.path !== "/" && to.path.endsWith("/")) {
    const { hash, path, query } = to;
    const nextPath = path.replace(/\/+$/, "") || "/";
    const nextRoute = { hash, path: nextPath, query };

    return navigateTo(nextRoute, { redirectCode: 301 });
  }
});
