export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return;

  let name = to.path.substring(1).replace(/\//g, "_");
  if (name === "") {
    name = "homepage";
  }

  to.meta.gtm = name;
});
