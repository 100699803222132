import { createGtm } from "@gtm-support/vue-gtm";

export default defineNuxtPlugin((nuxtApp) => {
  const env = useRuntimeConfig().public.env;
  if (env === "development") {
    return;
  }

  useServerHead({
    link: [
      {
        as: "script",
        href: "https://www.googletagmanager.com/gtm.js?id=GTM-5VPV3MM",
        rel: "preload",
        tagPriority: 20,
      },
      {
        as: "script",
        href: "https://consent.cookiebot.com/uc.js?cbid=e7535454-f4f9-40f4-a3fe-dd952a48d75b&implementation=gtm&consentmode-dataredaction=dynamic",
        rel: "preload",
        tagPriority: 20,
      },
      {
        as: "document",
        href: "https://consentcdn.cookiebot.com/sdk/bc-v4.min.html",
        rel: "preload",
        tagPriority: 20,
      },
      {
        as: "script",
        href: "https://consentcdn.cookiebot.com/consentconfig/e7535454-f4f9-40f4-a3fe-dd952a48d75b/state.js",
        rel: "preload",
        tagPriority: 20,
      },
      {
        as: "fetch",
        crossorigin: "anonymous",
        href: "https://consentcdn.cookiebot.com/consentconfig/e7535454-f4f9-40f4-a3fe-dd952a48d75b/settings.json",
        rel: "preload",
        tagPriority: 20,
      },
    ],
  });

  nuxtApp.vueApp.use(
    createGtm({
      compatibility: false,
      debug: env === "staging",
      defer: false,
      enabled: true,
      id: "GTM-5VPV3MM",
      loadScript: true,
      trackOnNextTick: false,
      vueRouter: useRouter(),
    }),
  );
});
