import type { Integration } from "@sentry/types";
import * as Sentry from "@sentry/vue";

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();

  const { public: config } = useRuntimeConfig();
  const {
    sentry: { dsn, environment, replaysEnable, replaysOnErrorSampleRate, replaysSessionSampleRate, tracesSampleRate },
  } = config;

  if (!dsn) {
    return;
  }

  const integrations: Integration[] = [
    Sentry.browserTracingIntegration({
      router,
    }),
  ];

  if (replaysEnable === "true") {
    integrations.push(Sentry.replayIntegration());
  }

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn,
    environment,
    integrations,
    replaysOnErrorSampleRate,
    replaysSessionSampleRate,
    tracePropagationTargets: ["localhost:3000", "haelsi.local", config.appUrl, `${config.apiUrl}/api`],
    tracesSampleRate,
  });
});
