import type { RouteLocationRaw } from "vue-router";

export function isExternalLink(link: RouteLocationRaw): boolean {
  if (typeof link !== "string") {
    return false;
  }

  const runtimeConfig = useRuntimeConfig();
  const appUrl = runtimeConfig.public.appUrl;

  if (link[0] === "/") {
    return false;
  }

  if (link.startsWith(appUrl)) {
    return false;
  }

  return link.startsWith("http") || link.startsWith("mailto:") || link.startsWith("tel:");
}
