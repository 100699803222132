import validate from "/vercel/path0/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45add_45gtm_45name_45global from "/vercel/path0/frontend/middleware/01.add-gtm-name.global.ts";
import _02_45set_45fresh_45instance_45global from "/vercel/path0/frontend/middleware/02.set-fresh-instance.global.ts";
import redirect_45trailing_45slash_45global from "/vercel/path0/frontend/middleware/redirect-trailing-slash.global.ts";
import redirect_45global from "/vercel/path0/frontend/middleware/redirect.global.ts";
import manifest_45route_45rule from "/vercel/path0/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45add_45gtm_45name_45global,
  _02_45set_45fresh_45instance_45global,
  redirect_45trailing_45slash_45global,
  redirect_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}