<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <LazySharedReimbursementModal />
    <LazySharedVorsorgeModal />
    <LazySharedNavigationModal />
    <LazySharedMediClassModal v-if="showMediclassModal" @close-modal="store = false" />
    <LazyWidgetsChat v-if="!isDevMode && isAboPage" />
    <LazySharedPreventiveCheckupModal />
    <LazySharedMembershipDrawer />
  </div>
</template>

<script setup lang="ts">
import { useEventListener, useLocalStorage } from "@vueuse/core";
import { watch } from "vue";
import { useGlobalStore } from "~/store/global";

onMounted(() => {
  useEventListener(window, "vite:preloadError", (event) => {
    event.preventDefault(); // to not throw the error
    window.location.reload();
  });
});

const route = useRoute();
const config = useRuntimeConfig();
const currentUrl = computed(() => `${config.public.appUrl}${route.path}`);
const isDevMode = ref(config.public.env === "development");
const isAboPage = computed(() => route.path.startsWith("/abo"));
const headCount = config.public.headCount;
const { fetchGlobal } = useGlobalStore();
fetchGlobal();

useSchemaOrg([
  defineOrganization({
    "@type": "MedicalOrganization",
    address: {
      addressCountry: "AT",
      addressLocality: "Wien",
      addressRegion: "Wien",
      postalCode: "1020",
      streetAddress: "Vorgartenstraße 206C",
    },
    description: `Schnelle Termine, kurze Wartezeiten und mehr als ${headCount} Fachärzt:innen und Therapeut:innen, die dich bestens betreuen - online und vor Ort.`,
    isAcceptingNewPatients: true,
    logo: "https://cms.haelsi.at/uploads/haelsi_favicon_4b59f72a32.png",
    medicalSpecialty: "http://schema.org/PrimaryCare",
    name: "haelsi",
    sameAs: [
      "https://app.haelsi.at",
      "https://mein.haelsi.at",
      "https://www.linkedin.com/company/haelsiat",
      "https://www.facebook.com/haelsi.at",
      "https://www.youtube.com/channel/UCE9dOd7t3TTquhLbxlV0duw",
      "https://instagram.com/haelsi.at",
    ],
    slogan: "Wir machen Gesundheit einfach.",
  }),
  defineWebSite({
    name: "haelsi",
  }),
  defineWebPage(),
]);

useHead({
  link: [
    {
      href: currentUrl,
      rel: "canonical",
    },
  ],
});

useSeoMeta({
  description: `Schnelle Termine, kurze Wartezeiten und ${headCount} Fachärzt:innen und Therapeut:innen, die dich bestens betreuen - online und vor Ort.`,
  ogImage: {
    alt: "haelsi",
    height: 1200,
    url: `${config.public.appUrl}/haelsi_social.jpg`,
    width: 630,
  },
  ogTitle: "Dein Gesundheitszentrum in 1020 und 1180 Wien - haelsi",
  ogUrl: currentUrl,
});

const showMediclassModal = ref(false);
const store = ref(false);

if (route.query.from === "mediclass") {
  store.value = useLocalStorage("show-mediclass-modal", true).value;

  setTimeout(() => {
    showMediclassModal.value = store.value;

    watch(store, () => {
      showMediclassModal.value = false;
    });
  }, 100);
}

if (route.query.gclid !== undefined) {
  useSessionStorage("gclid", route.query.gclid);
}

watch(
  isAboPage,
  (currentValue) => {
    document?.documentElement.style.setProperty("--show-widget", currentValue ? "block" : "none");
  },
  { immediate: true },
);
</script>

<style>
div[data-test-id="userlike-container"] {
  display: var(--show-widget);
}
</style>
